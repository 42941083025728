import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';

import { useRouteChange } from '../util/hooks';
import { GTMPageView } from '../util/googleTagManager';

import '../styles/index.scss';

/* If in development mode, inject axe accessibility tools. */
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    const ReactDOM = require('react-dom');
    const axe = require('@axe-core/react');

    axe(React, ReactDOM, 1000, {
        runOnly: ['best-practice', 'wcag2a', 'wcag2aa']
    });
}

/* Inject Sentry reporting. */
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = [];

    /*
     * If we're building a production bundle with Node, then rewrite the
     * project root as relative to 'app:///' so that source maps will work.
     */
    if (
        process.env.NEXT_IS_SERVER === 'true' &&
        process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
        integrations.push(
            new RewriteFrames({
                iteratee: (frame) => {
                    frame.filename = frame.filename.replace(
                        process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
                        'app:///'
                    );

                    frame.filename = frame.filename.replace('.next', '_next');
                    return frame;
                }
            })
        );
    }

    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations
    });
}

function App(props) {
    const { Component, pageProps, err } = props;

    /* Trigger a page view on in-app routing */
    useRouteChange(GTMPageView);

    return <Component {...pageProps} err={err} />;
}

export default App;
